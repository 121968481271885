import styled from '@emotion/styled';

export default styled.h1`
  font-size: 24px;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
`;
